<template>
  <b-modal v-model="modal" size="lg" hide-footer>
    <template #modal-title> Agregar Black Jack </template>
    <form @submit.prevent="save()">
      <div v-if="step === 1">
        <div class="mb-3 mx-auto">
          <multiselect
            v-model="value"
            label="name"
            track-by="name"
            :options="games"
          >
          </multiselect>
        </div>
      </div>
      <div v-if="step === 2">
        <limits-by-currencies
          :operator="operator"
          :currencies="currencies"
          @update-limits="updateLimits"
        />
      </div>
      <div v-if="step === 3">
        <resolve-bets
          :map-resolve-bets="mapResolveBets"
          @new-resolve-bets="handleChange"
        />
      </div>
      <div class="d-flex">
        <vs-button type="submit">Guardar</vs-button>
        <vs-button @click="closeModa()" type="button" success>
          Cancelar
        </vs-button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { mapActions, mapGetters } from 'vuex';
import LimitsByCurrencies from './LimitsByCurrencies.vue';
import ResolveBets from './ResolveBets.vue';

export default {
  props: {
    operator: {
      type: Object,
      default: () => {
        return {};
      },
    },
    games: {
      typeof: Array,
      default: () => {
        return [];
      },
    },
    currencies: {
      typeof: Array,
      default: () => {
        return [];
      },
    },
  },
  components: { Multiselect, LimitsByCurrencies, ResolveBets },
  data() {
    return {
      typesubmit: false,
      modal: false,
      betPays: [],
      value: null,
      pays: {},
      limits: {},
      step: 1,
    };
  },
  computed: {
    ...mapGetters({
      success: 'operators/getSuccess',
      mapResolveBets: 'operators/getResolveBets',
      getLimitsFormatted: 'operators/getLimitsFormatted',
      getNewResolveBets: 'operators/getNewResolveBets',
    }),
  },
  methods: {
    async save() {
      // this.typesubmit = true;
      switch (this.step) {
        case 1:
          await this.add();
          break;
        case 2:
          await this.configLimits();
          break;
        case 3:
          await this.configResolveBets();

          break;

        default:
          break;
      }
    },
    closeModa() {
      this.betPays = [];
      this.pays = {};
      this.limits = {};
      this.modal = false;
      this.$emit('closeGameMenu');
    },
    handleChange(values) {
      this.pays = values;
    },
    async add() {
      this.step = 2;
    },
    async configResolveBets() {
      try {
        const resolveBet = this.getNewResolveBets(
          this.pays,
          this.mapResolveBets
        );

        await this.addResolveBets({
          operatorUuid: this.operator.uuid,
          gameUuid: this.value.uuid,
          resolveBet,
        });

        if (!this.success)
          return this.$swal.fire('Error agregando resolve bet', '', 'error');

        this.$swal.fire('Resolve bet agregado', '', 'success');
        this.closeModa();
      } catch (err) {
        console.log('ERROR ', err);
      }
    },
    async configLimits() {
      try {
        const limits = this.getLimitsFormatted(
          this.currencies,
          this.limits,
          this.value
        );

        await this.addBlackJack({ operatorId: this.operator.uuid, limits });

        if (!this.success)
          return this.$swal.fire(
            'Error agregando juego al operador',
            '',
            'error'
          );

        this.$swal.fire('Juego agregado', '', 'success');
        this.step = 3;
      } catch (error) {
        console.log('Error guardando juego al operador', error);
      }
    },
    updateLimits(limits) {
      this.limits = limits;
    },
    ...mapActions({
      addBlackJack: 'operators/addBlackJack',
      fetchResolveBets: 'operators/fetchResolveBets',
      addResolveBets: 'operators/addResolveBets',
    }),
  },
  watch: {
    async value(newValue) {
      if (!newValue) return;
      const { uuid } = newValue;
      this.value = newValue;
      await this.fetchResolveBets({
        gameUuid: uuid,
        operatorUuid: this.operator.uuid,
      });
    },
  },
};
</script>
