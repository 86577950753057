<template>
  <Layout>
    <PageHeader
      :title="title"
      moda_title="Datos del operador"
      ref="pageForm"
      :items="items"
      :isAdmin="isItForAdmin"
    >
      <template>
        <PageForm
          :typeform="typeform"
          @closeModa="closeModa"
          :clients="clients"
          :roles="roles"
        />
      </template>
    </PageHeader>
    <ListRoulette
      :item="operatorRoulette"
      :operator="operator"
      :role="userRole"
      ref="ListRoulette"
      @addCurrencyRoulette="addCurrencyRoulette"
      @configLimits="configLimits"
    />
    <urls-view
      ref="viewUrls"
      :item="urlClient"
      :operatorId="operatorId"
      :urls="urls"
      @closeModa="closeModa"
      @getData="getData"
      :role="userRole"
    ></urls-view>

    <add-currency
      :currencies="currencies"
      :model="model"
      path="operators"
      ref="addCurrency"
      :operator="model"
      :controlDocs="isAddingOperator"
      @modifyCurrencies="modifyCurrencies"
    />

    <!-- <config-chips :chips="chips" ref="configChips" @handleGoBack="handleGoBack"></config-chips> -->

    <config-chips
      ref="configChips"
      :operatorCurrency="operatorCurrency"
      :isAddingChip="isAddingChip"
      :chips="chips"
      @closeModa="closemodal"
      @addAnotherChip="addAnotherChip"
      @handleGoBack="handleGoBack"
      @handleSelection="handleSelection"
      @nextPageChip="nextPageChip"
      @prevPageChip="prevPageChip"
      :chipPage="chipPage"
      :filterCurrency="filterCurrency"
    ></config-chips>

    <config-limits
      ref="configLimitOperator"
      :currencies="operatorCurrencies"
      :operator="operator"
      :limitsByCurrencies="limitsByCurrencies"
      :isUpdating="updatingLimits"
      :currWithOutLimits="currWithOutLimits"
      :noLimitsCurrencies="noLimitsCurrencies"
    ></config-limits>

    <AddRoulette
      ref="addRoulette"
      :roulettes="operatorRoulette"
      :operator="operator"
      @closeGameMenu="closeGameMenu"
    >
    </AddRoulette>
    <GameModalMenu
      ref="GameModalMenu"
      :model="model"
      :isAdding="isAdding"
      @openListGame="handleListGame"
    />
    <AddGame
      ref="addGame"
      :model="operator"
      :games="operatorGames"
      :path="path"
      :endpoint="endpoint"
      @closeGameMenu="closeGameMenu"
    ></AddGame>
    <ListGame
      :games="operatorGames"
      :client="operator"
      ref="ListExternalGames"
    />

    <!-- TODO: -->
    <MenuConfigChips
      @handleConfigChips="handleConfigChips"
      :operator="operator"
      ref="menuConfigChips"
    />

    <ListCurrencies
      ref="listCurrencies"
      :currencies="operatorCurrencies"
      :operator="operator"
    />
    <ListGames ref="ListGames" :opGames="operatorGames" :operator="operator" />
    <AddDragonTiger
      ref="addDragonTiger"
      :model="operator"
      :games="games"
      :path="path"
    />
    <ListDragonTiger
      :games="games"
      :client="operator"
      ref="ListDragonTiger"
      @updateOperators="getData"
    />
    <!-- WHEELS -->
    <AddWheel
      ref="addWheelComponent"
      :wheels="games"
      :operator="operator"
      :currencies="getOperatorCurrencies"
      @closeGameMenu="closeGameMenu"
    />
    <AddBlackJack
      ref="addBlackJackComponent"
      :games="games"
      :operator="operator"
      :currencies="getOperatorCurrencies"
      @closeGameMenu="closeGameMenu"
    />
    <ListWheels
      :item="getOperatorWheels"
      :operator="operator"
      :games="getWheels"
      :role="userRole"
      ref="ListWheelFortune"
      @addCurrencyRoulette="addCurrencyRoulette"
      @configLimits="configLimits"
      @closeModal="closeModa"
    />
    <ListBlackJack
      ref="ListBlackJackComponent"
      :operator="operator"
      :role="userRole"
      :item="getOperatorBlackJack"
      @closeModal="closeModa"
    />

    <ConfigButtons ref="ConfigButtonsComponent" @closeModal="closeModa" />
    <ConfigLobby ref="ConfigLobbyComponent" @closeModal="closeModa" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row" v-if="isItForAdmin">
              <Tooltip>
                <ol>
                  <li>
                    Agregar:
                    <ul>
                      <li>Nombre del operador</li>
                      <li>
                        Datos para inicio de sesion del operador. Colocar su rol
                        correspondiente. Podrás editar estos datos en el modulo
                        de usuarios.
                      </li>
                    </ul>
                  </li>
                  <li>Detalle del operador: Allí encontrarás su id.</li>
                  <li>Ruleta: El operador hereda las ruletas de su cliente.</li>
                  <li>
                    Bloqueo de operador: Esto quita la posibilidad de que sus
                    jugadores puedan acceder a las salas.
                  </li>
                </ol>
              </Tooltip>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    {{ $t('filter.show') }}&nbsp;
                    <vs-select v-model="perPage" color="dark" class="shadow-lg">
                      <vs-option
                        v-for="(op, index) in pageOptions"
                        :key="index"
                        :label="op"
                        :value="op"
                        >{{ op }}</vs-option
                      >
                    </vs-select>
                    &nbsp;{{ $t('filter.entries') }}
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label
                    class="d-inline-flex align-items-center"
                    v-if="hasPermissions()"
                  >
                    {{ $t('filter.client') }}:
                    <select
                      v-model="clientFilter"
                      name="client"
                      class="form-select form-select-sm m-2 border-0 shadow-lg"
                      placeholder="Seleccione.."
                      @change="getData()"
                    >
                      <option disabled selected>
                        {{ $t('helpers.select') }}...
                      </option>
                      <option
                        v-for="(item, index) in clients"
                        :key="index"
                        :value="item"
                        :selected="typeform.client == item._id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </label>

                  <label class="d-inline-flex align-items-center m-2">
                    {{ $t('filter.search') }}:
                    <vs-input
                      primary
                      v-model="filter"
                      type="search"
                      placeholder="Buscar..."
                      class="shadow-lg"
                    />
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>

            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-overlay :show="loadingOperator" rounded="sm">
                <b-table
                  :items="tableData"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  :loading="true"
                >
                  <!-- URLS -->
                  <template #cell(urls)="data">
                    <div class="d-flex justify-content-center">
                      <vs-button @click="openUrls(data.item)">Urls</vs-button>
                    </div>
                  </template>
                  <!-- RULETAS -->
                  <template #cell(games)="data">
                    <div class="d-flex justify-content-center">
                      <vs-button
                        variant="outline-primary"
                        @click="handleOpen(data.item)"
                      >
                        {{ $t('sidebar.games.text').toUpperCase() }}
                      </vs-button>
                    </div>
                  </template>
                  <!--  -->
                  <template #cell(createdAt)="data">
                    <vs-tooltip>
                      {{
                        moment(data.item.createdAt).format(
                          'DD/MM/YYYY | HH:mm:ss'
                        )
                      }}
                      <template #tooltip>
                        {{
                          moment
                            .utc(data.item.start_date)
                            .format('DD/MM/YYYY | HH:mm:ss')
                        }}
                      </template>
                    </vs-tooltip>
                  </template>
                  <!-- Column: Actions -->
                  <template #cell(actions)="data">
                    <div class="action-btn-container">
                      <b-dropdown
                        variant="link"
                        no-caret
                        class="position-absolute"
                      >
                        <template #button-content>
                          <i class="fa fa-fw fa-bars font-size-16" />
                        </template>
                        <div v-if="isItForAdmin">
                          <b-dropdown-item
                            :to="{
                              name: 'operators-show',
                              params: { id: data.item._id },
                            }"
                          >
                            <i class="uil-file-alt m-2 font-size-18" />
                            <span class="align-middle ml-5">
                              {{ $t('actions.subItems.details') }}
                            </span>
                          </b-dropdown-item>
                        </div>

                        <div v-if="isItForAdmin">
                          <b-dropdown-item @click="edit(data.item)">
                            <i class="uil-edit m-2 font-size-18" />
                            <span class="align-middle ml-5">{{
                              $t('actions.subItems.edit')
                            }}</span>
                          </b-dropdown-item>
                        </div>

                        <div>
                          <b-dropdown-item @click="addGame(data.item)">
                            <i class="uil-create-dashboard m-2 font-size-18" />
                            <span class="align-middle ml-5"
                              >{{ $t('helpers.add') }}
                              {{ $t('sidebar.games.text') }}</span
                            >
                          </b-dropdown-item>
                        </div>

                        <div v-if="isItForAdmin">
                          <b-dropdown-item @click="handleOrderGames(data.item)">
                            <i class="uil-sort-amount-down m-2 font-size-18" />
                            <span class="align-middle ml-5">
                              Ordenar Juegos
                            </span>
                          </b-dropdown-item>
                        </div>

                        <div v-if="isItForAdmin">
                          <b-dropdown-item
                            @click="handleConfigButtons(data.item)"
                          >
                            <i class="uil-border-out m-2 font-size-18" />
                            <span class="align-middle ml-5"> Botoneria </span>
                          </b-dropdown-item>
                        </div>

                        <div v-if="isItForAdmin">
                          <b-dropdown-item
                            @click="handleConfiglobby(data.item)"
                          >
                            <i class="uil-border-out m-2 font-size-18" />
                            <span class="align-middle ml-5">
                              Personalizacion de lobby
                            </span>
                          </b-dropdown-item>
                        </div>

                        <div>
                          <b-dropdown-item @click="addCurrency(data.item)">
                            <i class="uil-coins m-2 font-size-18" />
                            <span class="align-middle ml-5">{{
                              $t('actions.subItems.addCurrency')
                            }}</span>
                          </b-dropdown-item>
                        </div>

                        <div>
                          <b-dropdown-item @click="openMenuChips(data.item)">
                            <i class="uil-circle-layer m-2 font-size-18" />
                            <span class="align-middle ml-5">{{
                              $t('actions.subItems.configChips')
                            }}</span>
                          </b-dropdown-item>
                        </div>

                        <div v-if="isItForAdmin">
                          <b-dropdown-item @click="block(data.item._id)">
                            <i class="uil-chart-down m-2 font-size-18" />
                            {{ $t('actions.subItems.block') }}
                            {{ $t('filter.operator') }}
                          </b-dropdown-item>
                        </div>
                        <div v-if="isItForAdmin">
                          <b-dropdown-item @click="disblock(data.item._id)">
                            <i class="uil-check m-2 font-size-18" />
                            <span class="align-middle ml-5"
                              >{{ $t('actions.subItems.disblock') }}
                              {{ $t('filter.operator') }}</span
                            >
                          </b-dropdown-item>
                        </div>
                        <div v-if="isItForAdmin">
                          <b-dropdown-item @click="remove(data.item)">
                            <i
                              class="mdi mdi-delete-outline m-2 font-size-18"
                            />
                            <span class="align-middle ml-5">{{
                              $t('actions.subItems.delete')
                            }}</span>
                          </b-dropdown-item>
                        </div>
                      </b-dropdown>
                    </div>
                  </template>
                </b-table>
              </b-overlay>
            </div>
            <div class="row">
              <p class="text-center" v-if="!tableData.length">NO HAY DATA</p>
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import Swal from 'sweetalert2';
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import {
  PageForm,
  AddCurrency,
  ConfigChips,
  ConfigLimits,
  ConfigButtons,
  ListGames,
  UrlsView,
  AddRoulette,
  AddWheel,
  ListRoulette,
  ListWheels,
  AddBlackJack,
  ListBlackJack,
  ConfigLobby,
} from './components';
import {
  Tooltip,
  GameModalMenu,
  AddGame,
  ListGame,
  MenuConfigChips,
  ListCurrencies,
  ListDragonTiger,
  AddDragonTiger,
  PageHeader,
} from '@/components';
import { onlyAdmins } from '../../../router/guards/roles';
import { formatPayments } from '../../../state/modules/operators';
import {
  ROULETTE_NAME,
  DRAGON_NAME,
  EXTERNAL_GAMES_NAME,
  WHEEL_NAME,
  BLACK_JACK_NAME,
} from '@/helpers/CONSTANTS';
import { breadCrumbData } from '../../../helpers/breadcrumbs/breadCrumbs';
import { ADMIN_ROLE } from '../../../helpers/CONSTANTS';

/**
 * Users-table component
 */
export default {
  page: {
    title: 'Operators Table',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    AddCurrency,
    Layout,
    PageHeader,
    PageForm,
    ListRoulette,
    UrlsView,
    ConfigChips,
    ConfigLimits,
    ConfigButtons,
    Tooltip,
    AddRoulette,
    GameModalMenu,
    AddGame,
    ListGame,
    MenuConfigChips,
    ListCurrencies,
    ListGames,
    AddDragonTiger,
    ListDragonTiger,
    AddWheel,
    ListWheels,
    AddBlackJack,
    ListBlackJack,
    ConfigLobby,
  },
  data() {
    return {
      isAddingOperator: true,
      tableData: [],
      title: this.$t('sidebar.operators'),
      items: breadCrumbData.operators,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      clients: [],
      sortBy: 'age',
      sortDesc: false,
      fields: [
        {
          key: 'name',
          sortable: true,
          label: this.$t('operators.name').toUpperCase(),
        },
        {
          key: 'operatorId',
          sortable: true,
          label: this.$t('operators.operatorId').toUpperCase(),
          class: 'space-nowrap',
        },
        {
          key: 'createdAt',
          sortable: true,
          label: this.$t('operators.creationDate').toUpperCase(),
          formatter: (value) => {
            return new Date(value);
          },
        },
        {
          key: 'urls',
          sortable: false,
          label: this.$t('operators.URLs').toUpperCase(),
          class: 'space-nowrap text-center align-middle space-nowrap',
        },
        {
          key: 'games',
          sortable: false,
          label: this.$t('sidebar.games.text').toUpperCase(),
          class: 'text-center align-middle',
        },
        {
          key: 'available',
          sortable: false,
          label: 'STATUS',
          class: 'text-center align-middle',
          formatter: (value) => {
            return value ? 'DISPONIBLE' : 'BLOQUEADO';
          },
        },
        {
          key: 'actions',
          sortable: false,
          label: this.$t('actions.text').toUpperCase(),
          class: 'align-middle w-25',
        },
      ],

      typeform: {
        name: '',
        minBet: null,
        maxBet: null,
        client: null,
      },
      clientFilter: {},
      clientSave: {},
      urlClient: {},
      operatorRoulette: [],
      loading: false,

      operator: {},
      model: {},
      operators: [],
      operatorId: '',

      urls: {},
      chips: [],
      userRole: '',

      // CURRENCIES
      currencies: [],
      operatorCuerrencies: [],
      operatorCurrency: null,
      filterCurrency: {},

      // LIMITS
      updatingLimits: false,
      operatorCurrencies: [],
      noLimitsCurrencies: [],
      currWithOutLimits: [],
      limitsByCurrencies: [],

      // CHIPS
      isAddingChip: false,
      chipsForm: {
        chip: '',
        value: '',
        color: '',
        isActive: true,
      },
      chipPage: 1,

      userData: '',
      roles: [],
      isAdding: false,

      // ROLES
      readAllRoles: onlyAdmins,

      // EXTERNAL GAMES
      operatorGames: [],
      path: '',
      endpoint: '',
      // anotherGames
      games: [],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    user() {
      const user = JSON.parse(localStorage.getItem('user')).user;
      return user;
    },
    rows() {
      return this.tableData.length;
    },
    ...mapGetters({
      mapRoles: 'roles/getRoles',
      getOperatorCurrencies: 'operators/getCurrencies',
      getClientCurrencies: 'clients/getCurrencies',
      isSuccess: 'currencies/getSuccess',
      operatorSuccess: 'operators/getSuccess',
      getOperator: 'operators/getOperator',
      getOperatorRoulettes: 'operators/getRoulettes',
      getErrorMessage: 'operators/getErrorMessage',
      getGames: 'operators/getGamesByClientId',
      chipSuccess: 'chips/isSuccess',
      getChips: 'chips/getChips',
      mapOperatorChips: 'operators/getChips',
      getClientExternalGames: 'clients/getGames',
      getExternalGames: 'operators/getExternalGames',
      mapClients: 'clients/getClients',
      getClientRoulettes: 'clients/getClientRoulettes',
      getOperators: 'operators/getOperators',
      getOperatorGames: 'operators/getOperatorGames',
      getOperatorGamesAdminApi: 'adminApi/getOperatorGames',
      getOperatorDragonTigers: 'operators/getDragonTigers',
      getClientDragonTiger: 'clients/getDragonTigers',
      getWheels: 'wheel/getWheels',
      getBlackJack: 'blackJack/getGames',
      getOperatorWheels: 'operators/getWheels',
      getOperatorBlackJack: 'operators/getBlackJack',
      envs: 'notification/mapEnvs',
      getErrors: 'notification/getErrors',
      loadingOperator: 'operators/getLoading',
      isItForAdmin: 'user/onlyAdminRole',
      userIsClient: 'user/isClient',
    }),
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getUserRole();
    this.getRoles();
    this.fetchCurrencies({
      role: this.userData.role.name,
      user: this.userData,
    });
  },
  methods: {
    hasPermissions() {
      return [ADMIN_ROLE, 'ADMIN_OPERATOR', 'PARTNER'].includes(this.userRole);
    },
    openWinNotification(position = null, color, title, text, duration = 10000) {
      this.$vs.notification({
        color,
        position,
        title,
        text,
        duration,
        progress: 'auto',
      });
    },
    checkErrors() {
      if (this.getErrors.length) {
        this.getErrors.map(({ api, reason }) => {
          const message = `API: ${this.envs[api]} | REASON: ${reason}`;
          this.openWinNotification(null, 'warning', 'ERROR', message);
        });
      }
    },
    closeGameMenu() {
      this.$refs.GameModalMenu.modal = false;
    },
    addGame(item) {
      this.model = item;
      this.isAdding = true;
      this.$refs.GameModalMenu.modal = true;
    },
    handleOpen(item) {
      this.model = item;
      this.operator = item;
      this.isAdding = false;

      this.$refs.GameModalMenu.modal = true;
    },
    handleAddGame(game, model) {
      switch (game.name) {
        case ROULETTE_NAME:
          this.addRoulette(model);
          break;
        case DRAGON_NAME:
          this.addDragonTiger(model);
          break;
        case EXTERNAL_GAMES_NAME:
          this.addExternalGame(model);
          break;
        case WHEEL_NAME:
          this.addWheel(model);
          break;
        case BLACK_JACK_NAME:
          this.addBlackJack(model);
          break;

        default:
          break;
      }
    },
    async handleListGame(data) {
      const { game, model } = data;

      if (this.isAdding) return this.handleAddGame(game, model);

      switch (game.name) {
        case ROULETTE_NAME:
          {
            this.openRulettes(this.operator);
          }
          break;
        case DRAGON_NAME:
          {
            this.handleListDragonTigers(game, model);
          }
          break;
        case EXTERNAL_GAMES_NAME:
          {
            this.handleListExternalGames(game, model);
          }
          break;
        case WHEEL_NAME:
          {
            this.handleListWheels(game, model);
          }
          break;
        case BLACK_JACK_NAME:
          {
            this.handleListBlackJack(model);
          }
          break;
        default:
          break;
      }
    },
    nextPageChip() {
      this.chipPage++;
      this.handleSelection();
    },
    prevPageChip() {
      this.chipPage--;
      this.handleSelection();
    },
    async getRoles() {
      await this.fetchRoles();
      this.roles = this.mapRoles;
    },
    handleGoBack() {
      this.configChips();
    },
    async addAnotherChip() {
      try {
        await this.fetchDefaultChips();

        if (this.chipSuccess) {
          this.chips = this.getChips;
          this.isAddingChip = true;
        }

        return (this.$refs.configChips.modalChips = true);
      } catch (error) {
        console.log('Error obteniendo default chips', error);
      }
    },
    closemodal() {
      this.filterCurrency = {};
      this.$refs.modalChips = false;
      this.rouletteCurrency = null;
      this.rouletteId = '';
      this.chips = [];
    },
    openMenuChips(value) {
      this.operator = value;
      this.$refs.menuConfigChips.modal = true;
    },
    async configChips(value) {
      this.filterChip = {};
      this.filterCurrency = {};
      this.chips = [];
      this.chipPage = 1;
      if (value) {
        this.operator = value;
        this.operatorId = value._id;
      }

      try {
        await this.fetchOperatorChips({
          path: 'operators',
          operator: value || this.operator,
          filter: {
            ...this.filterChip,
            page: this.chipPage,
          },
        });

        await this.fetchOperatorCurrencies({
          path: 'operators',
          id: this.operator._id,
        });
        this.operatorCurrencies = this.getOperatorCurrencies;
        if (!this.getOperatorCurrencies.length)
          return this.handleAlertSwal(
            'info',
            'Debe agregar una moneda antes de configurar las fichas'
          );

        const resData = value || this.operator;
        this.operatorCurrency = {
          ...resData,
          currencies: this.getOperatorCurrencies,
        };

        if (this.mapOperatorChips.length) {
          this.isAddingChip = false;
          this.chips = this.mapOperatorChips.map((chip) => {
            return { ...chip, currency: chip.operatorCurrency[0] };
          });

          return (this.$refs.configChips.modalChips = true);
        }

        if (!this.mapOperatorChips.length && !this.operatorSuccess.ok) {
          this.isAddingChip = true;
        }

        const { data: defaultChips } = await this.$http.get('/chips');

        if (defaultChips.ok) {
          this.chips = defaultChips.chips;
        }

        return (this.$refs.configChips.modalChips = true);
      } catch (error) {
        console.log('Error obteniendo chips operator', error);
      }
    },
    async getClients() {
      try {
        await this.fetchClients();
        this.clients = this.mapClients;
      } catch (err) {
        console.log('ERROR GET CLIENTS', err);
      }
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onlyAdminsAndPartners() {
      return this.readAllRoles.includes(this.userRole);
    },
    async getData() {
      this.operatorId = '';
      if (!this.onlyAdminsAndPartners()) return;
      if (Object.entries(this.clientFilter).length) {
        const { _id: client, uuid } = this.clientFilter;
        const options = { params: { client } };
        const optionsAdminApi = { client: uuid || client };
        await this.fetchOperators({ options, optionsAdminApi });
        this.tableData = this.getOperators;
        this.operators = this.getOperators;
      } else {
        await this.fetchOperators();
        this.tableData = this.getOperators;
      }
    },
    closeModa() {
      this.$refs.pageForm.modal = false;
      this.$refs.viewUrls.modal = false;
      this.$refs.GameModalMenu.modal = false;
      this.operatorId = '';
      this.typeform = {
        name: '',
        client: null,
      };
      this.getUserRole();
    },
    async edit(item) {
      await this.fetchClientRoulettes({ id: item.client._id });

      let roulettesIds = [];

      if (this.getClientRoulettes.length) {
        this.getClientRoulettes.forEach(({ roulette }) =>
          roulettesIds.push(roulette._id)
        );
      }

      this.typeform = { ...item, client: item.client._id, roulettesIds };

      this.$refs.pageForm.modal = true;
    },
    async remove(operator) {
      const isConfirmed = await this.handleDeleteSwal(
        'Está seguro que desea eliminar este operador?'
      );

      if (!isConfirmed) return Swal.fire('Operador no eliminado', '', 'info');

      try {
        await this.removeOperator({ operator });

        if (!this.operatorSuccess)
          return this.handleAlertSwal('error', 'Error eliminando el operador');

        this.handleAlertSwal('success', 'Operador eliminado con exito');
        this.checkErrors();
        this.getData();
      } catch (error) {
        console.log('ERROR DELETE OPERATOR', error);
      }
    },
    async block(id) {
      const isConfirmed = await this.handleDeleteSwal(
        'Está seguro que desea bloquear este operador?'
      );

      if (!isConfirmed) return Swal.fire('Accion cancelada', '', 'info');
      try {
        const { data } = await this.$http.put(`operators/${id}/block`);

        if (!data.ok)
          return this.handleAlertSwal('error', 'Error bloqueando al operador');

        this.handleAlertSwal('success', 'Operador bloqueado con exito');
        this.getData();
      } catch (error) {
        console.log(error);
      }
    },
    async disblock(id) {
      const isConfirmed = await this.handleDeleteSwal(
        'Está seguro que desea desbloquear este operador?'
      );

      if (!isConfirmed) return Swal.fire('Accion cancelada', '', 'info');

      try {
        await this.$http.put(`operators/${id}/disblock`);

        this.handleAlertSwal('success', 'Operador desbloqueado con exito');
        this.getData();
      } catch (error) {
        console.log(error);
      }
    },
    async openRulettes(item) {
      this.loading = true;

      try {
        await this.fetchOperatorRoulettes({ id: item._id });
        if (!this.operatorSuccess)
          return Swal.fire(this.getErrorMessage, '', 'info');

        this.operator = this.getOperator;
        this.operatorRoulette = this.getOperatorRoulettes;

        this.loading = false;
        this.$refs.ListRoulette.modal = true;
      } catch (error) {
        console.log('ERROR OPEN ROULETTES', error);
        this.loading = false;
      }
    },
    async handleConfigChips(data) {
      // para configurar chips por defecto true
      if (data.defaultValues) {
        this.operator = data.operator;
        await this.fetchOperatorCurrencies({
          path: 'operators',
          id: this.operator._id,
        });
        this.operatorCurrencies = this.getOperatorCurrencies;
        this.$refs.listCurrencies.modal = true;
      }

      // para configurar personalizado false
      if (!data.defaultValues) {
        this.configChips(data.operator);
      }
    },
    async handleListDragonTigers(game, model) {
      const { uuid } = model;
      const operator = this.getOperators.find((op) => op.uuid === uuid);

      await this.fetchOperatorDragonTigers({ uuid });

      if (!this.getOperatorDragonTigers.length)
        return this.handleAlertSwal('info', 'No hay dragon tigers encontrados');

      this.games = formatPayments(this.getOperatorDragonTigers, operator);

      this.operator = operator;
      this.loading = false;

      this.$refs[game.ref].modal = true;
    },
    async handleListExternalGames(game, model) {
      await this.fetchExternalGames({ id: model._id });
      const operatorGames = this.getExternalGames
        .filter(({ externalGame }) => externalGame)
        .map(({ externalGame, _id }) => ({ ...externalGame, ref: _id }));

      this.operatorGames = operatorGames;
      this.operator = model;
      this.loading = false;

      this.$refs[game.ref].modal = true;
    },
    async handleListWheels(game, model) {
      await this.fetchWheels();
      await this.fetchOperatorWheels({ uuid: model.uuid });

      this.operator = model;
      this.loading = false;
      this.$refs[game.ref].modal = true;
    },
    async handleListBlackJack(model) {
      const { uuid } = model;
      await this.fetchBlackJack();
      await this.fetchOperatorBlackJack({ uuid });
      this.operator = model;
      this.loading = false;
      this.$refs.ListBlackJackComponent.modal = true;
    },
    openUrls(item) {
      this.urlClient = item;
      this.operatorId = item._id;
      const { endpointAuth, endpointWin, endpointBet, endpointRollback } = item;
      this.urls = {
        endpointAuth,
        endpointWin,
        endpointBet,
        endpointRollback,
      };
      this.$refs.viewUrls.modal = true;
    },
    async getUserRole() {
      this.userData = this.$store.getters['user/getUserData'];
      this.userRole = this.$store.getters['user/getRole'];
      const client = this.userData.client;

      if (Object.entries(this.clientFilter).length) {
        const { _id: client, uuid } = this.clientFilter;
        const options = { params: { client } };
        const optionsAdminApi = { client: uuid || client };
        await this.fetchOperators({ options, optionsAdminApi });
        this.tableData = this.getOperators;
        return (this.operators = this.getOperators);
      }

      if (this.onlyAdminsAndPartners()) {
        return this.getClients();
      }

      if (this.userIsClient) {
        const options = { params: { client } };
        await this.fetchOperators({ options });
        this.tableData = this.getOperators;
      }
    },
    async addCurrency(item) {
      this.toggleOverlayLoader();

      await this.fetchClientCurrencies({
        path: 'clients',
        id: item.client._id,
      });
      this.currencies = this.getClientCurrencies;

      await this.fetchOperatorCurrencies({ path: 'operators', id: item._id });
      const operatorCurrencies = this.getOperatorCurrencies;

      this.model = {
        ...item,
        currencies: operatorCurrencies,
        isOperator: true,
      };

      this.toggleOverlayLoader();

      this.$refs.addCurrency.modal = true;

      if (!this.getClientCurrencies)
        return Swal.fire(
          'El cliente del operador no tiene monedas',
          '',
          'info'
        );
    },
    async configLimits(roulette) {
      this.updatingLimits = false;
      this.operatorCurrencies = [];
      this.noLimitsCurrencies = [];
      this.currWithOutLimits = [];
      this.limitsByCurrencies = [];

      try {
        this.operatorId = this.operator._id;
        this.operator = { ...this.operator, roulette };

        const { data: currenciesLimits } = await this.$http.get(
          `operators/${this.operatorId}/currency-limits/${roulette._id}`
        );

        if (currenciesLimits.ok) {
          this.updatingLimits = true;
        }

        const { data } = await this.$http.get(
          `operators/${this.operatorId}/roulette/${roulette._id}`
        );

        const { currencies } = data.operatorRoulette;

        if (!currencies.length)
          return Swal.fire('Ruleta sin monedas', '', 'error');

        if (data.ok) {
          this.operatorCurrencies = currencies;

          if (currenciesLimits.ok) {
            const formattedCurrencies = [];
            const shorts = [];
            const currWithOutLimits = [];

            this.operatorCurrencies.map((curr) => {
              currenciesLimits.limits.filter((limit) => {
                if (curr.short === limit.short) {
                  shorts.push(curr.short);
                  formattedCurrencies.push({
                    ...curr,
                    ...limit,
                    _id: curr._id,
                    limitId: limit._id,
                  });
                }
              });
            });

            this.operatorCurrencies.filter((limit) => {
              if (!shorts.includes(limit.short)) {
                currWithOutLimits.push(limit);
              }
            });

            this.operatorCurrencies = formattedCurrencies;

            this.currWithOutLimits = currWithOutLimits;
          }

          this.noLimitsCurrencies = this.currWithOutLimits.map(({ short }) => {
            return {
              currency: short,
            };
          });

          this.limitsByCurrencies = this.operatorCurrencies.map(({ short }) => {
            return {
              currency: short,
            };
          });
        }

        return (this.$refs.configLimitOperator.configLimits = true);
      } catch (error) {
        console.log('Error obteniendo monedas del operador', error);
      }
    },
    async handleSelection() {
      this.$refs.configChips.loading = true;

      const { currency, limit } = this.filterCurrency;
      if (currency) {
        this.filterChip = { currencyId: currency._id };
      }

      await this.fetchOperatorChips({
        path: 'operators',
        operator: this.operator,
        filter: {
          ...this.filterChip,
          page: this.chipPage,
          limit,
        },
      });

      this.isAddingChip = false;
      if (!this.operatorSuccess) {
        this.chips = [];
      }

      if (this.operatorSuccess) {
        this.chips = this.mapOperatorChips.map((chip) => {
          return { ...chip, currency: chip.operatorCurrency[0] };
        });
      }
      this.$refs.configChips.loading = false;
    },
    async addCurrencyRoulette(roulette) {
      await this.fetchOperatorCurrencies({
        path: 'operators',
        id: this.operator._id,
      });
      // * La tabla ruleta tiene las currencies [] => default en db []
      this.model = roulette;

      // * El modelo para add currencies component, en este caso es la ruleta del operador
      this.currencies = this.getOperatorCurrencies;
      // *Las monedas a seleccionar son las del operador
      this.$refs.addCurrency.modal = true;
    },
    modifyCurrencies(currencies) {
      this.toggleLoading();
      if (this.model.isOperator) return this.addCurrencyOperator(currencies);
      this.addCurrencyOperatorRoulette(currencies);
    },
    async addCurrencyOperator(currencies) {
      const formatForOperator = currencies.map((curr) => curr._id);

      await this.addCurrencies({
        path: 'operators',
        id: this.model._id,
        currencies: formatForOperator,
      });
      await this.addCurrenciesModel({
        path: 'operators',
        currencies,
        uuid: this.model.uuid,
      });

      if (!this.isSuccess) {
        this.toggleLoading();
        return Swal.fire('Error guardando monedas', '', 'error');
      }

      this.$refs.addCurrency.modal = false;
      this.toggleLoading();
      this.getUserRole();
      Swal.fire('Monedas guardadas', '', 'success');
    },
    async addCurrencyOperatorRoulette(currencies) {
      const { _id, ...rest } = this.model;
      const dataToUpdate = { ...rest, currencies: currencies };

      await this.modifyRoulette({
        opId: this.operator._id,
        rouId: _id,
        model: dataToUpdate,
      });

      if (!this.operatorSuccess) {
        this.toggleLoading();
        return Swal.fire('Error editando la ruleta', '', 'error');
      }

      Swal.fire('Ruleta editada correctamente', '', 'success');
      this.toggleLoading();
      this.$refs.addCurrency.modal = false;
      this.$refs.ListRoulette.modal = false;
    },
    async addRoulette(item) {
      await this.fetchOperatorRoulettes({ id: item._id });

      await this.getRoulettesByClientId({
        path: 'roulettes',
        id: item.client._id,
      });

      this.operator = item;

      const gamesIds = this.getGames.map((game) => game._id);

      const operatorGamesIds = this.getOperatorRoulettes.map(
        (game) => game._id
      );

      const difference = gamesIds.filter(
        (x) => operatorGamesIds.indexOf(x) === -1
      );

      const roulettesToAdd = this.getGames.filter((roulette) =>
        difference.includes(roulette._id)
      );

      this.operatorRoulette = roulettesToAdd;

      this.$refs.addRoulette.modal = true;
    },
    async addDragonTiger(model) {
      const { uuid, clientUuid } = model;
      await this.fetchClientDragonTigers({ uuid: clientUuid });
      await this.fetchOperatorDragonTigers({ uuid });

      const gamesIds = this.getClientDragonTigers.map((game) => game.uuid);
      const operatorGamesIds = this.getOperatorDragonTigers.map(
        (dragonTiger) => dragonTiger.uuid
      );

      const difference = gamesIds.filter(
        (x) => operatorGamesIds.indexOf(x) === -1
      );
      const gamesToAdd = this.getClientDragonTigers.filter((game) =>
        difference.includes(game.uuid)
      );

      this.operator = model;
      this.games = gamesToAdd;
      this.path = `/operator/${model.uuid}/add-game`;

      this.$refs.addDragonTiger.modal = true;
    },
    async addWheel(model) {
      const { uuid } = model;

      await this.fetchOperatorCurrencies({ id: model._id, path: 'operators' });
      this.operator = model;
      this.games = await this.getWheelsToAdd(uuid);

      this.$refs.addWheelComponent.modal = true;
    },
    async addBlackJack(model) {
      const { uuid } = model;
      await this.fetchOperatorCurrencies({ id: model._id, path: 'operators' });
      this.operator = model;
      this.games = await this.getBlackJackToAdd(uuid);
      this.$refs.addBlackJackComponent.modal = true;
    },
    async getWheelsToAdd(uuid) {
      await this.fetchWheels();
      await this.fetchOperatorWheels({ uuid });

      const gamesIds = this.getWheels.map((game) => game.uuid);
      const operatorGamesIds = this.getOperatorWheels.map(
        (wheel) => wheel.uuid
      );

      const difference = gamesIds.filter(
        (x) => operatorGamesIds.indexOf(x) === -1
      );
      const gamesToAdd = this.getWheels.filter((game) =>
        difference.includes(game.uuid)
      );
      return gamesToAdd;
    },
    async getBlackJackToAdd(uuid) {
      await this.fetchBlackJack();
      await this.fetchOperatorBlackJack({ uuid });

      const gamesIds = this.getBlackJack.map((game) => game.uuid);

      const operatorGamesIds = this.getOperatorBlackJack.map(
        (game) => game.uuid
      );

      const difference = gamesIds.filter(
        (x) => operatorGamesIds.indexOf(x) === -1
      );
      const gamesToAdd = this.getBlackJack.filter((game) =>
        difference.includes(game.uuid)
      );
      return gamesToAdd;
    },
    async addExternalGame(model) {
      await this.fetchExternalGames({ id: model._id });
      await this.fetchClientExternalGames({ id: model.client._id });

      const gamesIds = this.getClientExternalGames
        .filter(({ externalGame }) => externalGame)
        .map(({ externalGame }) => externalGame._id);
      const operatorGamesIds = this.getExternalGames
        .filter(({ externalGame }) => externalGame)
        .map(({ externalGame }) => externalGame._id);

      const difference = gamesIds.filter(
        (x) => operatorGamesIds.indexOf(x) === -1
      );

      const gamesToAdd = this.getClientExternalGames
        .filter(({ externalGame }) => externalGame)
        .map(({ externalGame }) => externalGame)
        .filter((game) => difference.includes(game._id));

      this.operator = model;
      this.operatorGames = gamesToAdd;
      this.path = 'operators';
      this.endpoint = 'add-external-game';

      this.$refs.addGame.modal = true;
    },
    // ORDER GAMES
    async handleOrderGames(operator) {
      this.toggleOverlayLoader();

      await this.fetchOperatorGames({ id: operator._id });

      this.operator = operator;
      this.operatorGames = this.getOperatorGames;

      this.toggleOverlayLoader();

      this.$refs.ListGames.modal = true;
    },
    async handleConfigButtons(operator) {
      const { buttonLobby, buttonSupport } = operator;

      this.$refs.ConfigButtonsComponent.modal = true;
      this.$refs.ConfigButtonsComponent.operator = operator;
      this.$refs.ConfigButtonsComponent.buttonLobby = buttonLobby;
      this.$refs.ConfigButtonsComponent.buttonSupport = buttonSupport;
    },
    async handleConfiglobby(operator) {
      this.$refs.ConfigLobbyComponent.operator = { ...operator };

      const {
        logo,
        background,
        cruppierLogo,
        loaderLogo,
        primaryColor,
        secondaryColor,
        useLogo,
        _id,
        client,
        uuid,
      } = operator;

      const dataToUpdate = {
        logo,
        background,
        cruppierLogo,
        loaderLogo,
        primaryColor,
        secondaryColor,
        useLogo,
        _id,
        client,
        uuid,
      };

      this.$refs.ConfigLobbyComponent.form = dataToUpdate;

      this.$refs.ConfigLobbyComponent.modal = true;
    },
    // END ORDER GAMES
    async handleDeleteSwal(title) {
      const { isConfirmed } = await Swal.fire({
        title,
        showDenyButton: true,
      });

      if (!isConfirmed) return false;

      return true;
    },
    handleAlertSwal(icon, title) {
      Swal.fire({
        position: 'center',
        icon,
        title,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    ...mapActions({
      fetchRoles: 'roles/fetchRoles',
      fetchDefaultChips: 'chips/fetchChipsDefault',
      fetchOperatorCurrencies: 'operators/fetchOperatorCurrencies',
      fetchClientCurrencies: 'clients/fetchClientCurrencies',
      addCurrencies: 'currencies/modifyCurrencies',
      addCurrenciesModel: 'currencies/addCurrenciesToModel',
      fetchCurrencies: 'currencies/fetchCurrencies',
      modifyRoulette: 'operators/modifyOpRoulette',
      fetchOperatorRoulettes: 'operators/getOperatorRoulettes',
      getRoulettesByClientId: 'operators/getGamesByClientId',
      fetchOperatorChips: 'operators/findChips',
      fetchClientExternalGames: 'clients/fetchExternalGames',
      fetchExternalGames: 'operators/fetchExternalGames',
      fetchClients: 'clients/fetchClients',
      fetchClientRoulettes: 'clients/fetchClientRoulettes',
      fetchOperators: 'operators/fetchOperators',
      fetchOperatorGames: 'operators/fetchOperatorGames',
      fetchOperatorGamesAdminApi: 'adminApi/fetchOperatorGames',
      fetchOperatorDragonTigers: 'operators/fetchDragonTigers',
      fetchClientDragonTigers: 'clients/fetchDragonTiger',
      removeOperator: 'operators/remove',
      fetchWheels: 'wheel/fetchWheels',
      fetchBlackJack: 'blackJack/fetchGames',
      fetchOperatorWheels: 'operators/fetchOperatorWheels',
      fetchOperatorBlackJack: 'operators/fetchOperatorBlackJack',
    }),
    ...mapMutations({
      toggleLoading: 'actions/toggleLoading',
      toggleOverlayLoader: 'user/toggleOverlayLoader',
    }),
  },

  middleware: 'authentication',
};
</script>

<style scoped>
.space-nowrap {
  white-space: nowrap !important;
}

.action-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100px;
}
</style>
