<template>
  <div>
    <h5>{{ $t('configLimits.title') }}</h5>

    <div class="my-3 row px-2">
      <div class="col-4 text-center">
        <strong>{{ $t('configLimits.currency') }}.</strong>
      </div>
      <div class="col-4 text-center">
        <strong>{{ $t('configLimits.minBet') }}</strong>
      </div>
      <div class="col-4 text-center">
        <strong>{{ $t('configLimits.maxBet') }}</strong>
      </div>
    </div>
    <div>
      <div v-for="(currency, index) in currencies" :key="index">
        <div class="card p-4">
          <div class="row align-items-center">
            <div class="col-4">
              <p class="m-0">
                {{ $t('configLimits.currency') }}:
                <strong>{{ currency.short }}</strong>
              </p>
            </div>
            <div class="col-4">
              <vs-input
                @keyup="(e) => handleUpdate(e, currency)"
                :value="currency.minBet || null"
                class="shadow-lg"
                type="text"
                border
                name="minBet"
                minlength="1"
                required
              />
            </div>
            <div class="col-4">
              <vs-input
                @keyup="(e) => handleUpdate(e, currency)"
                :value="currency.maxBet || null"
                class="shadow-lg"
                type="text"
                border
                name="maxBet"
                minlength="1"
                required
              />
            </div>
            <vs-button
              type="button"
              class="delete-limit"
              success
              @click="deleteLimit(currency)"
            >
              <span class="uil-trash-alt text-light"></span>
            </vs-button>
            <div class="change-status-container">
              <vs-switch
                v-model="currency.gameStatus"
                :loading="loadingStatus"
                @click="handleChange(currency, currency.gameStatus)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: ['currencies'],
  data() {
    return {
      configLimits: false,
      typesubmit: false,
      typeform: {},
      limits: [],
      newLimits: {},
      errors: {},
      loadingStatus: false,
    };
  },
  computed: {
    ...mapGetters({
      success: 'operators/getSuccess',
    }),
  },
  methods: {
    async handleChange(curr) {
      this.loadingStatus = true;

      const { operator: uuid, currency: currencyUuid, game: gameUuid } = curr;

      await this.changeStatus({
        uuid,
        currencyUuid,
        gameUuid,
      });

      if (!this.success) Swal.fire('ERROR CHANGING STATUS', '', 'error');
      if (this.success) Swal.fire('STATUS CAMBIADO', '', 'success');

      this.loadingStatus = false;
      this.$emit('close-modal');
    },
    handleUpdate(e, currency) {
      const { value, name } = e.target;

      const { short, _id } = currency;

      this.newLimits[short] = {
        ...currency,
        ...this.newLimits[short],
        short,
        currency: _id,
        [name]: value,
      };

      this.$emit('update-limits', this.newLimits);
    },
    async deleteLimit(currency) {
      const { isConfirmed } = await Swal.fire(
        'Estas seguro en remover el limite?',
        '',
        'question'
      );

      if (!isConfirmed) return;

      await this.removeLimit(currency);

      if (!this.success)
        return Swal.fire('Error removiendo limite', '', 'error');

      Swal.fire('Limite removido', '', 'success');
      this.$emit('close-modal');
    },
    ...mapActions({
      removeLimit: 'operators/removeLimitWheel',
      changeStatus: 'operators/changeStatusWheel',
    }),
  },
};
</script>

<style scoped>
.delete-limit {
  position: absolute;
  right: -10px;
  top: 0;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
}

.change-status-container {
  display: block;
  position: absolute;
  width: 2rem;
  max-width: 12px;
  right: 2rem;
  bottom: -0.2rem;
}
</style>
