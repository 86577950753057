<template>
  <div>
    <form @submit.prevent="save()">
      <b-row>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t('operators.name') }}</label>
            <vs-input
              v-model="typeform.name"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.name.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.name.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.name.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>UUID</label>
            <vs-input v-model="typeform.uuid" border class="shadow-lg" />
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label> {{ $t('operators.form.client') }}</label>
            <div>
              <select
                v-model="typeform.client"
                name="client"
                class="form-select border-0 shadow-lg"
                :class="{
                  'is-invalid': typesubmit && $v.typeform.client.$error,
                }"
                placeholder="Seleccione.."
                @change="handleChange"
              >
                <option disabled selected>{{ $t('helpers.select') }}...</option>
                <option
                  v-for="(item, index) in clients"
                  :key="index"
                  :value="item"
                  :selected="typeform.client === item._id"
                >
                  {{ item.name }}
                </option>
              </select>
              <div
                v-if="typesubmit && $v.typeform.client.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.typeform.client.required">
                  Este campo es requerido.
                </span>
              </div>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t('operators.form.endpointAuth') }}</label>
            <div>
              <vs-input
                v-model="typeform.endpointAuth"
                class="shadow-lg"
                border
                placeholder="/auth"
                :class="{
                  'is-invalid': typesubmit && $v.typeform.endpointAuth.$error,
                }"
              />
              <div
                v-if="typesubmit && $v.typeform.endpointAuth.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.typeform.endpointAuth.required">
                  Este campo es requerido.
                </span>
              </div>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t('operators.form.endpointBet') }}</label>
            <div>
              <vs-input
                v-model="typeform.endpointBet"
                class="shadow-lg"
                border
                placeholder="/bet"
                :class="{
                  'is-invalid': typesubmit && $v.typeform.endpointBet.$error,
                }"
              />
              <div
                v-if="typesubmit && $v.typeform.endpointBet.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.typeform.endpointBet.required">
                  Este campo es requerido.
                </span>
              </div>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t('operators.form.endpointWin') }}</label>
            <div>
              <vs-input
                v-model="typeform.endpointWin"
                class="shadow-lg"
                border
                placeholder="/win"
                :class="{
                  'is-invalid': typesubmit && $v.typeform.endpointWin.$error,
                }"
              />
              <div
                v-if="typesubmit && $v.typeform.endpointWin.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.typeform.endpointWin.required">
                  Este campo es requerido.
                </span>
              </div>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t('operators.form.endpointRollback') }}</label>
            <div>
              <vs-input
                v-model="typeform.endpointRollback"
                class="shadow-lg"
                border
                placeholder="/rollback"
                :class="{
                  'is-invalid':
                    typesubmit && $v.typeform.endpointRollback.$error,
                }"
              />
              <div
                v-if="typesubmit && $v.typeform.endpointRollback.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.typeform.endpointRollback.required">
                  Este campo es requerido.
                </span>
              </div>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>URL GAMES</label>
            <div>
              <vs-input
                v-model="typeform.urlGames"
                class="shadow-lg"
                border
                placeholder="https://another-games.com"
              />
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="!typeform._id">
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t('initSession.role') }}</label>
            <label class="d-inline-flex align-items-center w-100">
              <select
                v-model="typeform.role"
                name="role"
                class="form-select form-control border-0 shadow-lg"
                :class="{
                  'is-invalid': typesubmit && $v.typeform.role.$error,
                }"
                placeholder="Seleccione.."
                :required="typeform._id ? false : true"
              >
                <option disabled selected>{{ $t('helpers.select') }}...</option>
                <option
                  v-for="(item, index) in roles"
                  :key="index"
                  :value="item._id"
                  :selected="typeform.role == item._id"
                >
                  {{ item.name }}
                </option>
              </select>
              <div
                v-if="typesubmit && $v.typeform.role.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.typeform.role.required">
                  Este campo es requerido.
                </span>
              </div>
            </label>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t('initSession.password') }}</label>
            <vs-input
              v-model="typeform.password"
              type="password"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.password.$error,
              }"
              :required="typeform._id ? false : true"
            />
            <div
              v-if="typesubmit && $v.typeform.password"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.password.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="mb-3 mb-0">
        <div class="d-flex">
          <vs-button>{{ $t('form.save') }}</vs-button>
          <vs-button @click="$emit('closeModa')" success type="button">
            {{ $t('form.abort') }}
          </vs-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import Swal from 'sweetalert2';

export default {
  props: {
    typeform: {
      type: Object,
      default: () => {
        return {};
      },
    },
    clients: {
      typeof: Array,
      default: () => {
        return [];
      },
    },
    roles: [],
  },
  data() {
    return {
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    typeform: {
      name: {
        required,
      },
      client: {
        required,
      },
      password: {
        // required,
      },
      role: {
        // required,
      },
      endpointAuth: {
        required,
      },
      endpointBet: {
        required,
      },
      endpointWin: {
        required,
      },
      endpointRollback: {
        required,
      },
    },
  },
  methods: {
    handleChange() {
      const client = this.clients.find(
        (client) => client._id === this.typeform.client._id
      );
      this.typeform.endpointAuth = client.endpointAuth;
      this.typeform.endpointBet = client.endpointBet;
      this.typeform.endpointWin = client.endpointWin;
      this.typeform.endpointRollback = client.endpointRollback;
    },
    async save() {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (!this.$v.$invalid) {
        //ACTUALIZAR OPERADOR
        if (this.typeform._id) {
          try {
            await this.update({ doc: this.typeform });
            if (!this.success)
              return Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Error actualizando el operador',
                showConfirmButton: false,
                timer: 1500,
              });

            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Operador guardado con exito',
              showConfirmButton: false,
              timer: 1500,
            });

            // await this.saveRoulettes()

            this.checkErrors();

            return this.$emit('closeModa');
          } catch (error) {
            console.log('ERROR ACTUALIZANDO OPERATOR', error);
          }
        } else {
          //AGREGAR OPERADOR
          try {
            await this.create({ doc: this.typeform });

            if (this.success) {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Operador registrado con exito',
                showConfirmButton: false,
                timer: 1500,
              });

              this.checkErrors();
              return this.$emit('closeModa');
            }
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Operador no registrado',
              showConfirmButton: true,
            });
            this.$emit('closeModa');
          } catch (error) {
            console.log('ERROR SAVING OPERATOR', error);
          }
        }
      }
    },
    async saveRoulettes() {
      try {
        const { data } = await this.$http.put(
          `/operators/${this.typeform._id}/assign-roulettes`,
          this.typeform.roulettesIds
        );

        if (!data.ok) Swal.fire('Error salvando ruletas', '', 'error');
      } catch (error) {
        console.log('ERROR SAVING ROULETTES', error);
      }
    },
    openWinNotification(position = null, color, title, text, duration = 10000) {
      this.$vs.notification({
        color,
        position,
        title,
        text,
        duration,
        progress: 'auto',
      });
    },
    checkErrors() {
      if (this.getErrors.length) {
        this.getErrors.map(({ api, reason }) => {
          const message = `API: ${this.envs[api]} | REASON: ${reason}`;
          this.openWinNotification(null, 'warning', 'ERROR', message);
        });
      }
    },
    ...mapActions({
      create: 'operators/create',
      update: 'operators/update',
    }),
  },
  computed: {
    user() {
      const user = JSON.parse(localStorage.getItem('user')).user;
      return user;
    },
    ...mapGetters({
      success: 'operators/getSuccess',
      envs: 'notification/mapEnvs',
      getErrors: 'notification/getErrors',
    }),
  },
};
</script>
