<template>
  <b-modal v-model="modal" size="xl" hide-footer centered>
    <template #modal-title>
      {{ operator.name }}
    </template>

    <div v-if="payments.length" class="row">
      <b-card v-for="(item, i) in payments" :key="i" class="col-4">
        <vs-input
          :value="item.pay"
          @keyup="(e) => handleLimit(e, item.optionBet)"
          type="text"
          class="shadow-lg"
          border
          name="maxBet"
          :label="`Option: ${item.optionBet}`"
          minlength="1"
          required
        />
      </b-card>
    </div>
    <div class="d-flex">
      <vs-button type="button" @click="saveLimits">Guardar</vs-button>
      <vs-button @click="closeModa" type="button" success> Cancelar </vs-button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    payments: {
      type: Array,
      default: () => [],
    },
    operator: {
      type: Object,
      default: () => ({}),
    },
    game: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      modal: false,
      newPayments: {},
    };
  },
  computed: {
    ...mapGetters({
      success: 'operators/getSuccess',
    }),
  },
  methods: {
    handleLimit(e, option) {
      const { value } = e.target;
      Object.assign(this.newPayments, {
        [option]: { optionBet: option, pay: Number(value) },
      });
    },
    closeModa() {
      this.newPayments = {};
      this.modal = false;
      this.$emit('closeModal');
    },
    async saveLimits() {
      try {
        const defaultPayments = this.payments.reduce(
          (acc, value) => Object.assign(acc, { [value.optionBet]: value }),
          {}
        );
        const configPayments = Object.values({
          ...defaultPayments,
          ...this.newPayments,
        });
        await this.setPayments({
          configPayments,
          uuid: this.operator.uuid,
          gameUuid: this.game.uuid,
        });
        if (!this.success)
          return this.$swal.fire({
            title: 'Error agregando pagos',
            icon: 'error',
          });
        this.$swal.fire({ title: 'Pagos agregados', icon: 'success' });
        this.closeModa();
      } catch (error) {
        console.log('ERROR SAVE LIMITS', error);
        this.$swal.fire({ title: 'Error agregando pagos', icon: 'error' });
      }
    },
    ...mapActions({
      setPayments: 'operators/setPayments',
    }),
  },
};
</script>
