<template>
  <b-modal v-model="modal" size="xl" hide-footer centered>
    <template #modal-title> JUEGOS: {{ operator.name }} </template>
    <div>
      <div class="order-container">
        <b-card
          v-for="(game, i) in games"
          :key="i"
          @dragenter.prevent
          @dragover.prevent
          :draggable="changingOrder"
          @dragstart="dragStart($event, game)"
          @drop="onDrop($event)"
          class="card-chip"
        >
          <div
            class="d-flex row align-items-center justify-content-between h-100"
          >
            <span class="d-block order position-absolute start-0 top-0">{{
              game.order
            }}</span>
            <h5>{{ game.game.name }}</h5>
            <p>Tipo de juego: {{ game.game.type }}</p>
          </div>
        </b-card>
      </div>
    </div>
    <div class="mb-3 mx-auto">
      <div class="d-flex justify-content-center">
        <vs-button @click.prevent="save()">
          {{ $t('form.save') }}
        </vs-button>
        <vs-button @click="closeModa()" type="button" success>
          {{ $t('form.abort') }}
        </vs-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Swal from 'sweetalert2';
import { formatOrderGames } from '@/views/pages/operators/helpers/drop';

export default {
  name: 'OperatorGames',
  props: ['opGames', 'operator'],
  data() {
    return {
      games: [],
      modal: false,
      changingOrder: true,
    };
  },
  computed: {
    ...mapGetters({
      success: 'operators/getSuccess',
    }),
  },
  methods: {
    defaultOrder() {
      const sorted = [...this.opGames]
        .sort((a, b) => a.order - b.order)
        .map((el, i) => ({
          ...el,
          order: i + 1,
        }));

      this.games = sorted;
    },
    dragStart(e, item) {
      if (!this.changingOrder) return;
      e.dataTransfer.dropEffect = 'move';
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('item', JSON.stringify(item));
    },
    onDrop(e) {
      if (!this.changingOrder) return;

      this.games = formatOrderGames(e, this.games);
    },
    closeModa() {
      this.modal = false;
      this.games = [];
    },
    async save() {
      try {
        console.log('this.games', this.games);
        await this.saveOrderGames({
          id: this.operator._id,
          games: this.games,
          uuid: this.operator.uuid,
        });
        if (!this.success)
          return Swal.fire('Error guardando el orden', '', 'info');
        Swal.fire('Orden guardado correctamente', '', 'success');
        this.closeModa();
      } catch (error) {
        console.log('ERROR SAVING ORDER', error);
      }
    },
    ...mapActions({
      saveOrderGames: 'operators/saveOrderGames',
    }),
  },
  watch: {
    opGames(newValue) {
      if (!newValue) return (this.games = []);
      this.defaultOrder();
    },
  },
};
</script>

<style scoped>
.card-chip:hover {
  background-color: rgb(180, 180, 180);
  cursor: grab;
}

.order-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, 9rem);
  gap: 10px;
}
</style>
