var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"size":"xl","hide-footer":"","centered":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.operator.name)+" ")]},proxy:true}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('div',[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.item,"fields":_vm.makeFields()},scopedSlots:_vm._u([(_vm.isItForAdmin)?{key:"cell(actions)",fn:function(data){return [_c('b-dropdown',{attrs:{"variant":"link","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"fa fa-fw fa-bars font-size-16"})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":() => {
                _vm.configLimits(data.item);
                _vm.version = 'V1';
              }}},[_c('i',{staticClass:"uil-file-alt m-2 font-size-18"}),_c('span',{staticClass:"align-middle ml-5"},[_vm._v(" "+_vm._s(_vm.$t('actions.subItems.configLimits').toLocaleUpperCase())+" ")])]),_c('b-dropdown-item',{on:{"click":() => {
                _vm.configLimitsV2(data.item);
                _vm.version = 'V2';
              }}},[_c('i',{staticClass:"uil-file-alt m-2 font-size-18"}),_c('span',{staticClass:"align-middle ml-5"},[_vm._v(" "+_vm._s(( _vm.$t('actions.subItems.configLimits') + ' V2' ).toLocaleUpperCase())+" ")])]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.configLimitsByFigure(data.item)}}},[_c('i',{staticClass:"uil-file-alt m-2 font-size-18"}),_c('span',{staticClass:"align-middle ml-5"},[_vm._v(" CONFIGURAR LIMITES POR FIGURA ")])]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.configPayments(data.item)}}},[_c('i',{staticClass:"uil-paypal m-2 font-size-18"}),_c('span',{staticClass:"align-middle ml-5"},[_vm._v("CONFIGURAR PAGO")])])],1)]}}:null],null,true)})],1),_c('ConfigPayments',{ref:"configPaymentsComponent",attrs:{"operator":_vm.operator,"payments":_vm.paysToEdit,"game":_vm.game},on:{"closeModal":_vm.closeModa}}),_c('b-modal',{attrs:{"size":"lg"},on:{"ok":function($event){$event.preventDefault();return _vm.saveLimits.apply(null, arguments)}},model:{value:(_vm.limitModal),callback:function ($$v) {_vm.limitModal=$$v},expression:"limitModal"}},[_c('Limits',{attrs:{"operator":_vm.operator,"currencies":_vm.currencies},on:{"update-limits":_vm.updateLimits,"close-modal":_vm.closeModa}})],1),_c('FiguresList',{ref:"FiguresListComponent",attrs:{"currencies":_vm.currencies,"figures":_vm.figures,"game":_vm.game,"operator":_vm.operator}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }