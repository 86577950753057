<template>
  <b-modal id="bv-modal-example" v-model="modalChips" size="lg" hide-footer>
    <template #modal-title>{{
      isAddingChip ? $t('helpers.add') + 'chips' : $t('configChips.title')
    }}</template>
    <div class="row mb-4" v-if="!chipSelected">
      <div>
        <div class="d-flex mb-3 justify-content-between" v-if="!isAddingChip">
          <div class="col-6">
            <vs-tooltip class="d-inline-flex">
              <template #tooltip>Regresar</template>
              <vs-button
                class="d-inline-block"
                type="button"
                success
                :disabled="!changingOrder"
                @click="handleBack"
              >
                &larrhk;
              </vs-button>
            </vs-tooltip>
            <vs-tooltip class="d-inline-flex">
              <template #tooltip>Selecciona una moneda primero</template>
              <vs-button
                class="d-inline-block"
                type="button"
                @click="changeOrder"
                :disabled="changingOrder"
              >
                Cambiar orden
              </vs-button>
            </vs-tooltip>
            <vs-tooltip class="d-inline-flex">
              <template #tooltip>Ej: 10, 50, 1K, 2M</template>
              <vs-button
                class="d-inline-block"
                type="button"
                @click="defaultOrder"
                :disabled="changingOrder"
              >
                Orden ascendente
              </vs-button>
            </vs-tooltip>
            <vs-tooltip class="d-inline-flex">
              <template #tooltip>Guardar</template>
              <vs-button
                class="d-inline-block"
                type="button"
                success
                :disabled="!changingOrder"
                @click="saveOrder"
              >
                <i class="uil-save"></i>
              </vs-button>
            </vs-tooltip>
            <vs-button
              class="d-inline-block"
              type="button"
              :disabled="!changingOrder"
              @click="clearUpdateOrder"
            >
              <i class="uil-filter-slash"></i>
            </vs-button>
          </div>
          <div class="col-4">
            <div class="d-flex align-items-center" v-if="operatorCurrency">
              <label class="me-2">{{ $t('filter.filter') }}: </label>
              <multiselect
                v-model="filterCurrency.currency"
                :options="formatOptions(operatorCurrency.currencies)"
                @input="handleFilter"
                label="name"
                placeholder="Selecciona una moneda"
                select-label="Click para filtrar"
                :disabled="changingOrder"
              >
              </multiselect>
            </div>
            <div class="d-flex align-items-center" v-if="operatorCurrency">
              <label class="me-2">{{ $t('filter.entries') }}: </label>
              <multiselect
                v-model="filterCurrency.limit"
                :options="entriesValues"
                @input="handleFilter"
                placeholder="Entradas"
                select-label="Cantidad de entradas"
              >
              </multiselect>
            </div>
          </div>
        </div>
        <div v-if="!chips.length">{{ $t('configChips.empty') }}</div>
        <b-overlay :show="loading" round="sm">
          <div
            class="row justify-content-center drop-zone"
            v-if="chips.length"
            ref="chipContainer"
          >
            <div
              v-for="(chip, index) in orderChips"
              :key="index"
              :class="`border-2 card card-chip mx-1 ${chip.order} ${changingOrder ? 'changingOrder' : ''}`"
              :style="chip.active ? 'opacity: 1' : 'opacity: 0.8'"
              :draggable="changingOrder"
              @dragstart="dragStart($event, chip)"
              @dragenter.prevent
              @dragover.prevent
              @drop="onDrop($event)"
            >
              <span class="d-none order">{{ chip.order }}</span>
              <span class="badge bg-secondary badge-status rounded-pill">{{
                chip.active ? $t('configChips.ok') : $t('configChips.noOk')
              }}</span>
              <div
                class="d-flex align-items-center"
                @click="() => selectChip(chip)"
              >
                <Chip :chip="chip" />
                <div class="chip-info">
                  <span
                    ><strong
                      >{{ $t('configChips.number').toUpperCase() }}:</strong
                    >
                    {{ chip.number }}</span
                  >
                  <span
                    ><strong
                      >{{ $t('configChips.value').toUpperCase() }}:</strong
                    >
                    {{ chip.value }} {{ chip.currency.short }}</span
                  >
                  <span
                    ><strong>{{ $t('configChips.firstColor') }}:</strong>
                    <br />
                    {{ chip.color.primary }}</span
                  >
                  <span
                    ><strong>{{ $t('configChips.secondColor') }}:</strong>
                    <br />
                    {{ chip.color.secondary }}</span
                  >
                </div>
              </div>
              <span class="badge bg-primary rounded-pill">{{
                $t('configChips.edit')
              }}</span>
              <span
                class="badge delete-chip bg-danger rounded-pill"
                @click="deleteChip(chip)"
                v-if="!isAddingChip"
              >
                <span class="uil-trash-alt me-1"></span>
                {{ $t('actions.subItems.delete') }}
              </span>
            </div>
          </div>
        </b-overlay>
        <div
          class="d-flex justify-content-between align-items-center"
          v-if="!isAddingChip"
        >
          <p class="btn btn-primary btn-plus" @click="addAnotherChip">
            +
            <span class="badge bg-secondary rounded-pill">{{
              $t('configChips.addMore')
            }}</span>
          </p>
          <div class="pagination">
            <b-button
              class="uil-arrow-left"
              variant="primary"
              @click="handlePrev"
              :disabled="chipPage === 1"
            ></b-button>
            <b-button
              class="uil-arrow-right"
              variant="primary"
              @click="handleNext"
              :disabled="chips.length < 6"
            ></b-button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="chipSelected">
      <p>
        <span class="btn btn-secondary me-3" @click="handleBack">&larrhk;</span
        >Regresar
      </p>
      <div
        class="d-flex justify-content-end ms-auto w-25 flex-column"
        v-if="operatorCurrency"
      >
        <label>{{ $t('configChips.selectCurrency') }}: </label>
        <multiselect
          v-model="typeform.currency"
          :options="formatOptions(operatorCurrency.currencies)"
          label="name"
          :placeholder="$t('configChips.selectCurrency')"
        ></multiselect>
        <div v-if="$v.typeform.currency.$error" class="invalid-feedback">
          <span v-if="!$v.typeform.currency.required"
            >Este campo es requerido</span
          >
        </div>
      </div>

      <div
        class="d-flex justify-content-center"
        v-if="Object.entries(typeform).length"
      >
        <Chip :chip="typeform" />
      </div>
      <form>
        <b-row class="mb-3 mx-auto">
          <b-col sm="12" md="6" class="mx-auto">
            <div class="mb-3">
              <label>{{ $t('configChips.number') }}</label>
              <input
                v-model="typeform.number"
                type="text"
                :class="`form-control ${
                  $v.typeform.number.$error ? 'is-invalid' : ''
                }`"
                placeholder="1000"
                name="providerId"
                maxlength="7"
              />
              <div v-if="$v.typeform.number.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.number.maxLength">
                  7 caracteres maximos</span
                >
                <span v-if="!$v.typeform.number.numeric" class="d-block">
                  Solo valores numericos</span
                >
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6" class="mx-auto">
            <div class="mb-3">
              <label
                >{{ $t('configChips.value') }} ({{
                  typeform.currency ? typeform.currency.name : 'MONEDA'
                }})</label
              >
              <input
                v-model="typeform.value"
                type="text"
                class="form-control"
                placeholder="1.5"
                name="providerId"
                maxlength="7"
                :class="`form-control ${
                  $v.typeform.value.$error ? 'is-invalid' : ''
                }`"
              />
              <div v-if="$v.typeform.value.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.value.maxLength">
                  7 caracteres maximos</span
                >
                <span v-if="!$v.typeform.value.numeric" class="d-block">
                  Solo valores numericos</span
                >
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="mb-3 mx-auto">
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>{{ $t('configChips.firstColor') }}</label>
              <input
                v-model="typeform.color.primary"
                type="color"
                class="form-control"
                placeholder="HEX"
                name="providerId"
              />
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>{{ $t('configChips.secondColor') }}</label>
              <input
                v-model="typeform.color.secondary"
                type="color"
                class="form-control"
                placeholder="HEX"
                name="providerId"
              />
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>{{ $t('configChips.active') }}</label>
              <input
                id="chipIsActive"
                type="checkbox"
                :checked="typeform.active"
                class="form-check-input ms-2"
                v-model="typeform.active"
              />
            </div>
          </b-col>
        </b-row>

        <div class="mb-3 mx-auto">
          <div class="d-flex justify-content-center">
            <vs-button @click.prevent="save()" v-if="isAddingChip">
              {{ $t('helpers.add') }}
            </vs-button>
            <vs-button @click.prevent="save()" v-if="!isAddingChip">
              {{ $t('form.save') }}
            </vs-button>
            <vs-button @click="closeModa()" type="button" success>
              {{ $t('form.abort') }}
            </vs-button>
          </div>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import Swal from 'sweetalert2';
import { maxLength, decimal, required } from 'vuelidate/lib/validators';
import Multiselect from 'vue-multiselect';
import { mapActions, mapGetters } from 'vuex';
import { formatDropValue } from '@/views/pages/operators/helpers/drop';
import { Chip } from '../../../../components';

export default {
  props: [
    'operatorCurrency',
    'chips',
    'isAddingChip',
    'chipPage',
    'filterCurrency',
  ],
  components: { Multiselect, Chip },
  computed: {
    ...mapGetters({
      opSuccess: 'operators/getSuccess',
      envs: 'notification/mapEnvs',
      getErrors: 'notification/getErrors',
    }),
  },
  data() {
    return {
      orderChips: [],
      chipSelected: false,
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      modalChips: false,
      typeform: {
        currency: null,
      },
      selectionOpen: false,
      chipSelectedDetail: {},
      changingOrder: false,
      entries: 10,
      entriesValues: [10, 25, 50, 100],
      loading: false,
    };
  },
  validations: {
    typeform: {
      number: {
        decimal,
        maxLength: maxLength(7),
      },
      currency: {
        required,
      },
      value: {
        decimal,
        maxLength: maxLength(7),
      },
    },
  },
  methods: {
    openWinNotification(position = null, color, title, text, duration = 10000) {
      this.$vs.notification({
        color,
        position,
        title,
        text,
        duration,
        progress: 'auto',
      });
    },
    checkErrors() {
      if (this.getErrors.length) {
        this.getErrors.map(({ api, reason }) => {
          const message = `API: ${this.envs[api]} | REASON: ${reason}`;
          this.openWinNotification(null, 'warning', 'ERROR', message);
        });
      }
    },
    async defaultOrder() {
      this.changeOrder();
      const ascendentChips = this.orderChips.sort((a, b) => a.value - b.value);
      this.orderChips = ascendentChips.map((chip, i) => ({
        ...chip,
        order: i,
      }));
      await this.saveOrder();
    },
    async saveOrder() {
      const isConfirmed = await this.handleDeleteSwal(
        '¿Estás seguro de guardar este orden?'
      );

      if (!isConfirmed) {
        this.clearUpdateOrder();
        return this.handleBack();
      }

      const dataToSave = this.orderChips.map(({ order, _id }) => ({
        order,
        _id,
      }));

      try {
        const { data } = await this.$http.put(
          `/operators/${this.operatorCurrency._id}/update-order-chips`,
          { chips: dataToSave }
        );

        if (!data.ok)
          return this.handleAlertSwal(
            'error',
            'Error actualizando el orden de las fichas'
          );

        this.handleAlertSwal('success', 'Chips actualizada').then(() => {
          this.clearUpdateOrder();
          this.handleBack();
        });
      } catch (error) {
        console.log('ERROR SAVE ORDER', error);
      }
    },
    orderDefaultChips() {
      const chips = [...this.chips];
      const formattedChips = chips.map((chip, i) => {
        if (typeof chip.order === 'number') {
          return {
            ...chip,
          };
        }
        return { ...chip, order: i + 10 }; //INFO: agrega 10, ya que por los momentos por moneda max hay 8 fichas, asi evitamos order value repetidos
      });

      this.orderChips = formattedChips.sort((a, b) => a.order - b.order);
    },
    clearUpdateOrder() {
      this.changingOrder = false;
      this.filterCurrency.currency = '';
      this.orderDefaultChips();
    },
    changeOrder() {
      if (!this.filterCurrency.currency)
        return Swal.fire('Selecciona una moneda primero', '', 'info');
      this.changingOrder = true;
    },
    dragStart(e, item) {
      if (!this.changingOrder) return;
      e.dataTransfer.dropEffect = 'move';
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('item', JSON.stringify(item));
    },
    onDrop(e) {
      if (!this.changingOrder) return;

      this.orderChips = formatDropValue(e, this.orderChips);
    },
    handleNext() {
      this.$emit('nextPageChip');
    },
    handlePrev() {
      this.$emit('prevPageChip');
    },
    handleFilter() {
      this.$emit('handleSelection');
    },
    formatOptions(currencies) {
      const shorts = currencies.map((curr) => {
        return { name: curr.short, _id: curr._id, uuid: curr.uuid };
      });
      return shorts;
    },
    async deleteChip(chip) {
      const isConfirmed = await this.handleDeleteSwal(
        '¿Está seguro en eliminar esta ficha?'
      );

      if (!isConfirmed) return;

      await this.removeChip({ chip, operator: this.operatorCurrency });

      if (!this.opSuccess)
        return this.handleAlertSwal('error', 'Error eliminando ficha');

      this.closeModa();
      return this.handleAlertSwal('success', 'Ficha eliminada');
    },
    addAnotherChip() {
      this.$emit('addAnotherChip');
    },
    handleBack() {
      this.chipSelected = false;
      this.chipSelectedDetail = {};
      this.typeform = {};
      this.changingOrder = false;
      this.filterCurrency.currency = '';
      this.$emit('handleGoBack');
    },
    async save() {
      this.typesubmit = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return console.log('ERROR', this.$v);
      }

      if (this.isAddingChip) return this.addChip();

      this.updateChip();
    },
    closeModa() {
      this.typeform = {};
      this.chipSelected = false;
      this.modalChips = false;
      this.chipSelectedDetail = {};
      this.$emit('closeModa');
    },
    async addChip() {
      try {
        const operator = this.operatorCurrency;
        const doc = { ...this.typeform };

        await this.assignChips({ operator, doc });

        if (!this.opSuccess) {
          return this.handleAlertSwal('error', 'Error adding chip').then(() => {
            this.closeModa();
          });
        }
        this.checkErrors();

        this.handleAlertSwal('success', 'Chip almacenada').then(() => {
          this.handleBack();
        });
      } catch (error) {
        console.log('Error guardando chip', error);
        return this.handleAlertSwal('error', 'Error guardando la chip');
      }
    },
    async updateChip() {
      try {
        await this.updateOpChip({
          operator: this.operatorCurrency,
          newChip: this.typeform,
        });

        if (!this.opSuccess)
          return this.handleAlertSwal('error', 'Error actualizando chip').then(
            () => {
              this.handleBack();
            }
          );

        this.checkErrors();
        return this.handleAlertSwal('success', 'Chip actualizada').then(() => {
          this.handleBack();
        });
      } catch (error) {
        console.log('Error guardando chip', error);
        return this.handleAlertSwal('error', 'Error guardando la chip');
      }
    },
    selectChip(chip) {
      this.chipSelectedDetail = chip;
      this.typeform = {
        number: chip.number,
        value: chip.value,
        color: chip.color,
        active: chip.active,
        ...chip,
      };
      this.chipSelected = true;
    },
    async handleDeleteSwal(title) {
      const { isConfirmed } = await Swal.fire({
        title,
        showDenyButton: true,
      });

      if (!isConfirmed) return false;

      return true;
    },
    handleAlertSwal(icon, title) {
      return Swal.fire({
        position: 'center',
        icon,
        title,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    ...mapActions({
      assignChips: 'operators/assignChips',
      removeChip: 'operators/removeChip',
      updateOpChip: 'operators/updateChip',
    }),
  },
  watch: {
    chips(value) {
      if (this.changingOrder) return;
      if (!value.length) return;
      this.orderDefaultChips();
    },
  },
};
</script>

<style scoped>
.pagination > * {
  width: 30px;
  height: 30px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10%;
  margin-left: 5px;
  cursor: pointer;
  color: #fff;
}

.delete-chip {
  bottom: 0;
}

.badge {
  position: absolute;
  right: -10px;
  display: none;
}

.badge-status {
  display: block;
  left: 0;
  top: 0;
  right: inherit;
}

.card-chip {
  max-width: 14rem;
}

.card:hover {
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.274);
  cursor: pointer;
}

.card:hover .badge {
  display: block;
}

.btn-plus {
  position: relative;
}

.btn-plus .badge {
  right: inherit;
}

.btn-plus:hover .badge {
  display: block;
}

.fish {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  min-width: 50px;
  height: 50px;
  margin: 15px;
  border-radius: 50%;
  -webkit-border-radius: 150px;
  -moz-border-radius: 150px;
  box-shadow:
    inset 0 0 1px 1px rgba(0, 0, 0, 0.569),
    0 0 5px 1px rgba(90, 90, 90, 0.66),
    inset 0 35px 1px rgba(255, 255, 255, 0.334);
  -moz-box-shadow:
    inset 0 0 1px 1px rgba(0, 0, 0, 0.576),
    0 0 5px 1px rgba(90, 90, 90, 0.392),
    inset 0 35px 1px rgba(255, 255, 255, 0.397);
  -webkit-box-shadow:
    inset 0 0 1px 1px rgba(0, 0, 0, 0.1),
    0 0 5px 1px rgba(90, 90, 90, 0.595),
    inset 0 35px 1px rgba(255, 255, 255, 0.2);
  border-width: 6px;
  border-style: dashed;
}

.fish span {
  font-family: Verdana;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  font-weight: bolder;
}

.chip-info {
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.changingOrder {
  outline: 1px dashed grey;
  outline-offset: 2.5px;
}
</style>
