<template>
  <b-modal v-model="modal" size="xl" hide-footer centered>
    <template #modal-title>
      {{ operator.name }}
    </template>
    <div>
      <b-table striped hover :items="item" :fields="makeFields()">
        <template #cell(actions)="data" v-if="isItForAdmin">
          <b-dropdown variant="link" no-caret>
            <template #button-content>
              <i class="fa fa-fw fa-bars font-size-16" />
            </template>
            <b-dropdown-item
              @click="
                () => {
                  configLimits(data.item);
                  version = 'V1';
                }
              "
            >
              <i class="uil-file-alt m-2 font-size-18" />
              <span class="align-middle ml-5">
                {{ $t('actions.subItems.configLimits').toLocaleUpperCase() }}
              </span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="
                () => {
                  configLimitsV2(data.item);
                  version = 'V2';
                }
              "
            >
              <i class="uil-file-alt m-2 font-size-18" />
              <span class="align-middle ml-5">
                {{
                  (
                    $t('actions.subItems.configLimits') + ' V2'
                  ).toLocaleUpperCase()
                }}
              </span>
            </b-dropdown-item>
            <b-dropdown-item @click="configLimitsByFigure(data.item)">
              <i class="uil-file-alt m-2 font-size-18" />
              <span class="align-middle ml-5">
                CONFIGURAR LIMITES POR FIGURA
              </span>
            </b-dropdown-item>
            <b-dropdown-item @click="configPayments(data.item)">
              <i class="uil-paypal m-2 font-size-18" />
              <span class="align-middle ml-5">CONFIGURAR PAGO</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </div>
    <ConfigPayments
      :operator="operator"
      :payments="paysToEdit"
      :game="game"
      ref="configPaymentsComponent"
      @closeModal="closeModa"
    />
    <b-modal v-model="limitModal" @ok.prevent="saveLimits" size="lg">
      <Limits
        :operator="operator"
        :currencies="currencies"
        @update-limits="updateLimits"
        @close-modal="closeModa"
      />
    </b-modal>
    <FiguresList
      ref="FiguresListComponent"
      :currencies="currencies"
      :figures="figures"
      :game="game"
      :operator="operator"
    />
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ConfigPayments from './ConfigPayments.vue';
import Limits from './Limits.vue';
import FiguresList from './FiguresList.vue';
import { ADMIN_ROLE } from '@/helpers/CONSTANTS';

export default {
  components: { ConfigPayments, Limits, FiguresList },
  props: {
    item: {
      type: Array,
      default: () => {
        return [];
      },
    },
    games: {
      type: Array,
      default: () => {
        return [];
      },
    },
    operator: Object,
    role: String,
  },
  data() {
    return {
      modal: false,
      fields: [],
      paysToEdit: [],
      game: {},
      currencies: [],
      limitModal: false,
      newLimits: {},
      version: 'V1',
      figures: [],
    };
  },
  computed: {
    ...mapGetters({
      success: 'operators/getSuccess',
      limits: 'operators/getLimits',
      getLimitsFormatted: 'operators/getLimitsFormatted',
      getCurrencies: 'currencies/getCurrencies',
      isItForAdmin: 'user/onlyAdminRole',
    }),
  },
  methods: {
    makeFields() {
      const globalFields = [
        {
          key: 'name',
          label: 'RUEDA',
          class: 'text-center',
        },
      ];
      const adminFields = [
        {
          key: 'actions',
          sortable: false,
          label: this.$t('actions.text'),
          class: 'align-middle  text-center',
        },
      ];
      if (this.role === ADMIN_ROLE) {
        return [...globalFields, ...adminFields];
      }

      return globalFields;
    },
    closeModa() {
      this.modal = false;
      this.limitModal = false;
      this.$emit('closeModal');
    },
    configLimits(item) {
      const { currencyAndLimits } = item;

      this.currencies = currencyAndLimits.map(
        ({ currency, minBet, maxBet }) => ({
          minBet,
          maxBet,
          short: currency.isoCode,
          ...currency,
        })
      );

      const currenciesWithOutLimits = this.operator.currencies
        .filter(
          (curr) => !this.currencies.find(({ isoCode }) => isoCode === curr)
        )
        .map((short) => {
          const currency = this.getCurrencies.find((c) => c.short === short);

          return {
            ...currency,
            minBet: 0,
            maxBet: 0,
            short,
          };
        });

      this.currencies.push(...currenciesWithOutLimits);

      this.limitModal = true;
      this.game = item;
    },
    async configLimitsV2(item) {
      const { uuid } = this.operator;

      const limits = await this.fetchOperatorLimits({
        uuid,
        gameUuid: item.uuid,
      });

      this.currencies = limits.map((limit) => ({
        ...limit,
        minBet: limit.minBet,
        maxBet: limit.maxBet,
        short: limit.short,
      }));

      const currenciesWithOutLimits = this.operator.currencies
        .filter((curr) => !this.currencies.find(({ short }) => short === curr))
        .map((short) => {
          const currency = this.getCurrencies.find((c) => c.short === short);

          return {
            // ...currency,
            currency: currency.uuid,
            minBet: 0,
            maxBet: 0,
            short,
          };
        });

      this.currencies.push(...currenciesWithOutLimits);

      this.limitModal = true;
      this.game = item;
    },
    async configLimitsByFigure(item) {
      // console.log("item", item);

      const { betPays } = item;

      this.figures = betPays;

      const { uuid } = this.operator;

      const limits = await this.fetchOperatorLimits({
        uuid,
        gameUuid: item.uuid,
      });

      this.currencies = limits.map((limit) => ({
        ...limit,
        minBet: limit.minBet,
        maxBet: limit.maxBet,
        short: limit.short,
      }));

      this.$refs.FiguresListComponent.modal = true;
      this.game = item;
    },
    updateLimits(limits) {
      this.newLimits = limits;
    },
    async saveLimits() {
      if (this.version === 'V2') return this.saveLimits2();

      const limits = this.getLimitsFormatted(
        this.currencies,
        this.newLimits,
        this.game
      );

      await this.addWheel({
        operatorId: this.operator.uuid,
        wheelLimits: limits,
      });

      if (!this.success)
        return this.$swal.fire('Error actualizando limites', '', 'error');

      this.$swal.fire('Limites actualizados', '', 'success');
      this.closeModa();
    },
    async saveLimits2() {
      const limits = this.getLimitsFormatted(
        this.currencies,
        this.newLimits,
        this.game,
        'V2'
      );

      const toUpdate = limits.filter((el) => el.uuid);
      const toCreate = limits.filter((el) => !el.uuid);

      const newLimits = await this.addOperatorLimits({
        operator: this.operator.uuid,
        game: this.game.uuid,
        limits: toCreate,
      });

      if (!newLimits)
        this.$swal.fire('Error actualizando limites', '', 'error');

      const updatedLimits = await this.updateOperatorLimits({
        operator: this.operator.uuid,
        game: this.game.uuid,
        limits: toUpdate,
      });

      if (!updatedLimits)
        this.$swal.fire('Error actualizando limites', '', 'error');

      this.$swal.fire('Limites actualizados', '', 'success');
      this.closeModa();
    },
    async configPayments(item) {
      this.paysToEdit = await this.getPaysToEdit({
        games: this.games,
        operator: this.operator,
        item,
      });
      this.game = item;
      this.$refs.configPaymentsComponent.modal = true;
    },
    ...mapActions({
      getPaysToEdit: 'operators/getPaysToEdit',
      addWheel: 'operators/addWheel',
      fetchOperatorLimits: 'operators/fetchOperatorLimits',
      addOperatorLimits: 'operators/addOperatorLimits',
      updateOperatorLimits: 'operators/updateOperatorLimits',
    }),
  },
};
</script>
